.products-page {
  &__header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
  }

  &__table {
  }

  .table {
    &__column {
      &--name {
        //max-width: 100px;
        //min-width: 100px;
        //width: 100px;
      }

      &--email {
      }

      &--role {
        min-width: 170px;
      }

      &--tag {
        min-width: 90px;
      }

      &--tag-item {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--last-activity {
        min-width: 180px;
      }

      &--actions {
        min-width: 170px;
      }
    }

    .user-table-info {
      &__container {
      }

      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      &__name {
        margin: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }

      &__name-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }

      &__email {
        margin: 4px 0 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  & .permissions-icons {
    &__icon {
      font-size: 22px;
      color: $gray-400;
      margin: 0 5px;

      &--big {
        font-size: 25px;
      }

      &--active {
        color: $success;
      }
    }
  }
}
